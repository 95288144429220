.select-wrapper {
    position: relative;
    display: inline-block;
}

.select-wrapper:after {
    content: '\0025BC';
    font: normal normal normal 12px/1 FontAwesome;
    color: #37748e;
    right: 14px;
    top: 23%;
    height: 26px;
    padding: 15px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
}