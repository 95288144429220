table, td {
    font-size: 1.5rem/* 24px */;
    line-height: 2rem/* 32px */;
    text-align: left;
  }

table{
    border-collapse:separate;
    border-spacing: 0;
}

td{
    border: 2px solid #37748E;
    padding-left:  1rem;
    padding-right: 1rem;
    font-weight: 600;
}

td:first-child{
    border-right: 0px;
}

td:first-child, td:last-child{
    border-bottom: 0px;
}

tr:last-child td:first-child { 
    border-bottom-left-radius: 10px;
    border-bottom: 2px solid #37748E;
}

tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
    border-bottom: 2px solid #37748E;
}

@media (min-width: 640px) {
    td{
        font-size: 1.25rem/* 20px */;
        line-height: 1.75rem/* 28px */;
    }
}

@media (min-width: 1024px) {
    td {
        font-size: 1.5rem/* 20px */;
        line-height: 2rem/* 28px */;
    }
}

